/**
 * 看板权限
 */
// 拥有看板权限的学校id列表
// 西南财经大学,四川信息职业技术学院
export const dashboardSchoolList = ["59977128740917248-1", "190753622680670208-1"]
// 拥有看板V2权限的学校id列表
// 西南财经大学,四川信息职业技术学院
export const dashboardV2SchoolList = ["59977128740917248-1", "190753622680670208-1"]

/**
 * 考核权限
 */
// 能够使用课堂考核功能的学校列表
// 测试学校，成都大学
export const examSchoolList = ["49533847984934912", "457463292223295488"]
// 能够使用单题考核功能的学校列表
// 测试学校
export const examSingleSchoolList = ["49533847984934912"]

/**
 * 学校官网权限
 */
// 能够手动修改学校官网配置的学校id列表
// 重庆青年职业技术学院 演示学校
export const expWebConfigList = ["49538854775951360", "277074055142576128"]
// 编辑器显示上传文件按钮的学校列表
// 成都大学
export const editorShowUploadList = ["457463292223295488"]

/**
 * 学习记录功能权限
 */
// 能够使用学习记录功能的学校列表
// 测试学校，浙江经济职业技术学院，天津铁道职业技术学院
export const learnRecordSchoolList = ["49533847984934912", "398869193484668928","434813771949543424"]